import React from "react"
import { Container, Col } from "react-bootstrap"
import Layout from "../components/LayoutTwo"
import { Helmet } from "react-helmet"

class Grazie extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Italian Art Trust O.N.L.U.S. - Grazie</title>
          <meta
            name="description"
            content="A thank you note for contributers as well as the IBAN account number associated to the Italian Art Trust for the purpose of contributing to the project via a bank transfer"
          />
        </Helmet>
        <Container>
          <section className="section" id="contatti">
            <div className="section__wrap">
              <h1 className="section__header">GRAZIE!</h1>
              <p>Sottoscrizione avvenuta con successo.</p>
              <p>
                Per sostenere Italian Art Trust puoi versare un importo a tua
                scelta mediante bonifico bancario sul seguente conto corrente
                inserendo nella causale “Erogazione Liberale Italian Art Trust
                Onlus”
              </p>
              <h3 className="iban">IBAN: IT64 K030 7501 603C C1510 8641 05</h3>
              <Col md={{ span: 4, offset: 4 }} className="btn__container">
                <a href="/">
                  <button className="IAT__button" variant="success" href="/">
                    RITORNA AL SITO
                  </button>{" "}
                </a>
              </Col>
            </div>
          </section>
        </Container>
      </Layout>
    )
  }
}

export default Grazie
